import { typeChecker } from '@/helpers/type'

const dateManager = {
  format(date, format = 'Y-m-d') {
    if (!typeChecker.isDate(date)) {
      return ''
    }
    if (format === 'Y/M/D') {
      const year = "'" + String(date.getFullYear()).slice(-2)
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}/${month}/${day}`
    }

    if (format === 'Y-m-d') {
      const padZero = (num) => {
        return `0${num}`.slice(-2)
      }
      return (
        date.getFullYear() +
        '-' +
        padZero(date.getMonth() + 1) +
        '-' +
        padZero(date.getDate())
      )
    }

    if (format === 'Y-m') {
      const padZero = (num) => {
        return `0${num}`.slice(-2)
      }
      return date.getFullYear() + '-' + padZero(date.getMonth() + 1)
    }
  },

  toDate(value) {
    if (!value) {
      return ''
    }
    if (typeChecker.isDate(value)) {
      return value
    }
    return new Date(value)
  },

  addDate(date, months, day) {
    let dateAdd = new Date(date.getFullYear(), date.getMonth() + months, 1)
    const monthEndDay = this.getMonthEndDay(dateAdd)
    dateAdd.setDate(day <= monthEndDay ? day : monthEndDay)
    return dateAdd
  },

  compareDate(date1, date2, formula = '>') {
    if (formula === '>') {
      return this.format(date1) > this.format(date2)
    }
  },

  getMonthEndDay(date) {
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return endDate.getDate()
  },

  calculateMonthDiff(startAt, endAt, maxDiff) {
    if (!endAt) {
      return maxDiff
    }
    let diff =
      (endAt.getYear() - startAt.getYear()) * 12 +
      endAt.getMonth() -
      startAt.getMonth()
    if (diff > maxDiff) {
      diff = maxDiff
    }
    if (diff < 0) {
      diff = 0
    }
    return diff
  },
}

export default Object.freeze(dateManager)
