<template>
  <span v-show="!!item && sortParams.value === item.value">
    <v-icon v-show="sortParams.mode === 'asc'" dark small>ti-arrow-up </v-icon>
    <v-icon v-show="sortParams.mode === 'desc'" dark small
      >ti-arrow-down
    </v-icon>
  </span>
</template>

<script>
export default {
  props: {
    item: Object,
    sortParams: Object,
  },
}
</script>
