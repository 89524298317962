<template>
  <v-row no-gutters class="px-3">
    <v-col cols="4">
      <div class="fs-10" :class="editable ? 'mt-3' : 'mt-2'" v-text="label" />
    </v-col>
    <v-col cols="8">
      <v-text-field
        v-if="editable"
        v-model="amount"
        single-line
        dense
        hide-details
        :disabled="disabled"
        :readonly="!editable"
        :tabindex="tabindex"
        class="align-right"
        @focus="inputFocusAmount"
        @blur="blurFocusAmount"
        @input="$emit('input')"
      />
      <div v-else class="right fs-10 mt-2">
        {{ String(amount) + (valueAfterAdjustment? `→ ${valueAfterAdjustment}` : '') }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { currencyToNumber, numberToCurrency } from 'Helpers/journal'

export default {
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      validator: (prop) => typeof prop === 'number' || typeof prop === 'string',
      required: true,
      default: '',
    },
    valueAfterAdjustment: {
      validator: (prop) => typeof prop === 'number' || typeof prop === 'string',
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: null,
    },
    emitFocusEvent: {
      type: Boolean,
      default: false,
    },
    emitBlurEvent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    amount: {
      get() {
        return this.value
      },
      set(val) {
        if (val === null || val === undefined) {
          this.$emit('select', '0')
        } else {
          this.$emit('select', val)
        }
      },
    },
  },
  methods: {
    inputFocusAmount() {
      try {
        if (!this.editable || this.emitFocusEvent) {
          this.$emit('focus')
          return
        }
        if (this.amount == 0) {
          this.amount = ''
        } else {
          this.amount = currencyToNumber(this.amount)
        }
      } catch (e) {
        console.log(e)
      }
    },
    blurFocusAmount() {
      try {
        if (!this.editable || this.emitBlurEvent) {
          this.$emit('blur')
          return
        }
        this.amount = numberToCurrency(this.amount)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
