import JapaneseHolidays from 'japanese-holidays'
import dateManager from './manager'

export { dateManager }

export function formatDate(date) {
  try {
    if (date) {
      return (
        date.year +
        '/' +
        date.month +
        '/' +
        date.day +
        ' ' +
        date.hour +
        ':' +
        date.minute +
        '   '
      )
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

export function toDate(dateString) {
  try {
    return new Date(dateString)
  } catch (error) {
    console.log(error)
    return undefined
  }
}

export function changeDateStringFormat(dateString) {
  try {
    return dateString.slice(0, 16).replace('T', ' ')
  } catch (error) {
    console.log(error)
    return ''
  }
}

export function convertToDatetime(date) {
  try {
    if (date) {
      return new Date(
        date.year,
        Number(date.month) - 1,
        date.day,
        date.hour,
        date.minute
      )
    } else {
      return null
    }
  } catch (e) {
    console.log(e)
    return null
  }
}

export function dateAdd(dt, dd, u) {
  let y = dt.getFullYear()
  let m = dt.getMonth()
  let d = dt.getDate()
  let r = new Date(y, m, d)
  if (typeof u == 'undefined' || u == 'D') {
    r.setDate(d + dd)
  } else if (u == 'M') {
    m += dd
    y += parseInt(m / 12)
    m %= 12
    let e = new Date(y, m + 1, 0).getDate()
    r.setFullYear(y, m, d > e ? e : d)
  }
  return r
}

export function monthDiff(dt1, dt2) {
  let y1 = dt1.getFullYear()
  let m1 = dt1.getMonth()
  let y2 = dt2.getFullYear()
  let m2 = dt2.getMonth()
  let r = (y2 - y1) * 12 + m2 - m1
  return r
}

export function formatDateWithHyphen(date) {
  let y = date.getFullYear()
  let m = ('00' + (date.getMonth() + 1)).slice(-2)
  let d = ('00' + date.getDate()).slice(-2)
  return y + '-' + m + '-' + d
}

export function convertHyphenFormatToDate(dateString) {
  //yyyy-mm-dd ⇨ date
  try {
    const array = dateString.split('-')
    return new Date(Number(array[0]), Number(array[1] - 1), Number(array[2]))
  } catch (e) {
    console.log(e)
    return null
  }
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isBankHoliday(date) {
  if (!isValidDate(date)) {
    return false
  }
  // 以下はsrc/views/payment/components/Table/PaymentTable.vueのisHolidayからコピー
  if (JapaneseHolidays.isHoliday(date)) {
    return true
  }
  if (date.getDay() == 0 || date.getDay() == 6) {
    // 土日
    return true
  }
  if (date.getMonth() == 0) {
    // 年初
    if (date.getDate() == 2 || date.getDate() == 3) {
      return true
    }
  }
  if (date.getMonth() == 11) {
    // 年末
    if (date.getDate() == 31) {
      return true
    }
  }
  return false
}

export function avoidBankHoliday(date) {
  while (isBankHoliday(date)) {
    date.setDate(date.getDate() - 1);
  }
  return date
}
